// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import "trix"
import "@rails/actiontext"

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import AstronomerPage from "../pages/astronomer_page"
import SessionPage from "../pages/session_page"

Rails.start();
ActiveStorage.start();

window.onload = () => {
  AstronomerPage.initialize();
  SessionPage.initialize();
};

require("trix")
require("@rails/actiontext")
