import Chart from 'chart.js/auto';
import { Utils } from 'chart.js';

Chart.defaults.color = "#fff";

const CHARTS = {};

function buildCharts() {
  Array.from(document.getElementsByClassName('session-statistic-graph'))
    .forEach((element) => {
      const dataset = JSON.parse(element.dataset.chartData);

      const chart = new Chart(element, {
        type: 'line',
        data: { datasets: [dataset] },
        options: {
            scales: {
              x: {
                type: 'linear',
                ticks: { stepSize: 1 },
              },
            },
            plugins: {
              title: {
                display: true,
                text: dataset.label,
              }
            }
        }
      });

      CHARTS[element.dataset.statisticType] = chart;
    });
}

function updateCard(newContent) {
  document.getElementById('session-card-update-target').innerHTML = newContent;
}

function updateChart(chart, newData) {
  chart.data.datasets[0].data = newData;
  chart.update();
}

function reloadCard() {
  fetch(window.location.href + '.json')
    .then(response => response.json())
    .then((data) => {
      updateCard(data.card);

      Array.from(document.getElementsByClassName('session-statistic-graph'))
        .forEach((element) => {
          const statisticType = element.dataset.statisticType;
          const dataset = data[statisticType];
          const chart = CHARTS[statisticType];

          updateChart(chart, dataset.data);
        });

        setTimeout(() => reloadCard(), 30 * 1000);
      });
}

const initialize = () => {
  if (document.body.dataset.controller !== "sessions" ||
      document.body.dataset.action !== "show") {
    return;
  }

  const chart = buildCharts();
  setTimeout(() => { reloadCard() }, 30 * 1000);
};

export default { initialize };
