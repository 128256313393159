import Chart from 'chart.js/auto';
import { Utils } from 'chart.js';

Chart.defaults.color = "#fff";

const CHARTS = {
  'astronomer-monthly-statistics': 'past three years',
  'astronomer-rig-statistics': 'past year by telescope'
};

function buildMonthlyChart() {
  const element = document.
                  getElementById('astronomer-monthly-statistics-chart');

  const data = JSON.parse(element.dataset.chartData);

  const chart = new Chart(element, {
      type: 'bar',
      data,
      options: {
          scales: {
            y: {
              beginAtZero: true
            }
          },
          plugins: {
            title: {
              display: true,
              text: 'Imaging Hours by Month (Past Three Years)'
            }
          }
      }
  });
}

function buildRigChart() {
  const element = document.
                  getElementById('astronomer-rig-statistics-chart');

  const data = JSON.parse(element.dataset.chartData);

  const chart = new Chart(element, {
      type: 'bar',
      data,
      options: {
          scales: {
            x: { stacked: true },
            y: {
              beginAtZero: true,
              stacked: true
            }
          },
          plugins: {
            title: {
              display: true,
              text: 'Imaging Hours by Telescope (Past Year)'
            }
          }
      }
  });
}

let currentChart = 'astronomer-monthly-statistics';

function toggleChart() {
  const oldChart = currentChart;

  if (currentChart === 'astronomer-monthly-statistics') {
    currentChart = 'astronomer-rig-statistics';
  } else {
    currentChart = 'astronomer-monthly-statistics';
  }

  document.getElementById(oldChart).classList.add('hidden');
  document.getElementById(currentChart).classList.remove('hidden');
  document.getElementById('stat-chart-toggle').textContent =
    `Show ${CHARTS[oldChart]}`;
}

const initialize = () => {
  if (document.body.dataset.controller !== "astronomers" ||
      document.body.dataset.action !== "show") {
    return;
  }

  buildMonthlyChart();
  buildRigChart();

  document
    .getElementById('stat-chart-toggle')
    .addEventListener('click', toggleChart);
};

export default { initialize };
